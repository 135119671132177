<template>
  <CCol xl="6">
    <CCard>
      <CCardHeader
        ><h5 class="card-title">Perfiles de usuario con acceso</h5></CCardHeader
      >
      <CCardBody>
        <CRow class="justify-content-center">
          <CCol lg="10">
            <CRow class="mb-3" v-if="user.profileId < -10">
              <CCol><p>Administradores</p></CCol>
              <CCol class="flex justify-content-end"
                ><CSwitch
                  class="mx-1"
                  color="success"
                  shape="pill"
                  v-bind="labelIcon"
                  @update:checked="checkProfile(-10)"
                  ref="switch-10"
                  :checked="blocked.includes(-10)"
                  :disabled="disableFields.includes(-10)"
              /></CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol><p>NIBSA</p></CCol>
              <CCol class="flex justify-content-end"
                ><CSwitch
                  class="mx-1"
                  color="success"
                  shape="pill"
                  v-bind="labelIcon"
                  @update:checked="checkProfile(0)"
                  ref="switch0"
                  :checked="blocked.includes(0)"
                  :disabled="disableFields.includes(0)"
              /></CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol><p>ASPM</p></CCol>
              <CCol class="flex justify-content-end"
                ><CSwitch
                  class="mx-1"
                  color="success"
                  shape="pill"
                  v-bind="labelIcon"
                  @update:checked="checkProfile(10)"
                  ref="switch10"
                  :checked="blocked.includes(10)"
                  :disabled="disableFields.includes(10)"
              /></CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol><p>Administradores de Concesionario</p></CCol>
              <CCol class="flex justify-content-end"
                ><CSwitch
                  class="mx-1"
                  color="success"
                  shape="pill"
                  v-bind="labelIcon"
                  @update:checked="checkProfile(20)"
                  ref="switch20"
                  :checked="blocked.includes(20)"
                  :disabled="disableFields.includes(20)"
              /></CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol lg="9"><p>Concesionarios</p></CCol>
              <CCol lg="3" class="flex justify-content-end"
                ><CSwitch
                  class="mx-1"
                  color="success"
                  shape="pill"
                  v-bind="labelIcon"
                  @update:checked="checkProfile(21)"
                  ref="switch21"
                  :checked="blocked.includes(21)"
                  :disabled="disableFields.includes(21)"
              /></CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol lg="9"><p>Usuario NFIT</p></CCol>
              <CCol lg="3" class="flex justify-content-end"
                ><CSwitch
                  class="mx-1"
                  color="success"
                  shape="pill"
                  v-bind="labelIcon"
                  @update:checked="checkProfile(30)"
                  ref="switch30"
                  :checked="blocked.includes(30)"
                  :disabled="disableFields.includes(30)"
              /></CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter class="flex justify-content-center">
        <div size="lg" class="d-inline-flex align-items-center panic-button">
          <DangerIcon class="danger-icon" />
          <span>PANIC BUTTON</span>
          <CSwitch
            class="ml-2"
            color="success"
            shape="pill"
            v-bind="labelText"
            ref="panicButton"
            @update:checked="updateBlocked"
            :checked="blocked.length > 0 && initValues"
            :disabled="blocked.length === 0"
          />
        </div>
      </CCardFooter>
    </CCard>
  </CCol>
</template>

<script>
import DangerIcon from "@/components/atoms/DangerIcon";
import Api from "@/services/Api";
export default {
  name: "Configuration",
  components: { DangerIcon },
  data() {
    return {
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      labelText: {
        labelOn: "ON",
        labelOff: "OFF",
      },
      // This variable "initValues" is created to evaluate whether the blocked values
      // are the results of the initial request or not, so we can switch the panic button
      initValues: true,
      blocked: [],
      disableFields: [],
      profileIds: [-10, 0, 10, 20, 21, 30],
      user: this.$store.getters.getCurrentUser,
    };
  },
  methods: {
    checkProfile(profile) {
      const ref = `switch${profile}`;
      this.initValues = false;
      if (this.$refs[ref].state) {
        this.blocked = this.blocked.splice();
        this.profileIds.forEach((value) => {
          if (value >= profile) {
            if (value > profile) {
              this.disableFields.push(value);
            }
            this.blocked.push(value);
          }
        });
      } else {
        this.disableFields = this.disableFields.splice();
        this.blocked = this.blocked.filter((element) => element < profile);
      }
    },
    updateBlocked() {
      let requestParams;
      if (this.$refs.panicButton.state) {
        this.disableFields = this.disableFields.splice();
        this.disableFields = [...this.profileIds];
        requestParams = `?profileCode=${Math.min(...this.blocked)}&block=1`;
      } else {
        this.blocked = this.blocked.splice();
        this.disableFields = this.disableFields.splice();
        requestParams = `?profileCode=-10&block=0`;
      }
      Api.sendPost(`sys/updateProfileStatus${requestParams}`)
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  async beforeCreate() {
    const blockedResults = await Api.sendGet("sys/profileStatus");
    blockedResults.data.data.map((element) => {
      if (element.isBlocked === 1) {
        this.blocked.push(element.profileCode);
      }
      if (this.blocked.length > 0) {
        this.blocked.sort();
        const blockedCopy = [...this.blocked];
        this.disableFields = [...blockedCopy];
      }
    });
  },
};
</script>

<style lang="scss">
.panic-button {
  background-color: #ed5565;
  color: #fff;
  height: 44px;
  padding: 1rem;
  border-radius: 8px;
  &:disabled {
    background-color: #8a93a2;
    border-color: #8a93a2;
  }
  .danger-icon {
    width: 26px;
    height: auto;
    fill: white;
    margin: 0 0.5rem 0 0;
  }
}
</style>
