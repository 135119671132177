import { render, staticRenderFns } from "./DangerIcon.vue?vue&type=template&id=ece40810&scoped=true&"
import script from "./DangerIcon.vue?vue&type=script&lang=js&"
export * from "./DangerIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ece40810",
  null
  
)

export default component.exports